import React from 'react';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { makeStyles } from '@material-ui/core/styles';
import { array, object } from 'prop-types';
import SliderButton from '../Buttons/SliderButton';
import COLORS from '../../constants/colors';

const useStyles = makeStyles(theme => ({
  root: {
    '& .slick-slider': { display: 'flex' },
    '& .slick-list': {
      margin: '0 20px',
      width: '-webkit-fill-available',
      [theme.breakpoints.down('md')]: { margin: '0 5px' },
      paddingBottom: ({ dots }) => (dots ? '35px' : ''),
    },
  },
  sliderDots: {
    bottom: '0px !important',
    '& button:before': {
      color: `${COLORS.SILVER} !important`,
      opacity: '.3 !important',
      fontSize: `10px !important`,
    },
    '& .slick-active button:before': {
      opacity: '1 !important',
    },
  },
}));

const Slider = ({ children, settings }) => {
  const { dots } = settings;
  const classes = useStyles({ dots });

  const defaultSettings = {
    dotsClass: `slick-dots ${classes.sliderDots}`,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    prevArrow: <SliderButton />,
    nextArrow: <SliderButton next />,
    responsive: [
      {
        breakpoint: 1750,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1425,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={classes.root}>
      <SlickSlider {...defaultSettings} {...settings}>
        {children}
      </SlickSlider>
    </div>
  );
};

Slider.propTypes = {
  children: array,
  settings: object,
};

Slider.defaultProps = {
  children: '',
  settings: {},
};

export default Slider;
