import React from 'react';
import ArrowLefttIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { string, func, bool } from 'prop-types';
import COLORS from '../../constants/colors';

const useStyles = makeStyles({
  root: {
    height: 'fit-content',
    width: 'fit-content',
    alignSelf: 'center',
    padding: 0,
    color: COLORS.SILVER,
    '& svg': {
      fontSize: 50,
    },
    '&.MuiButton-root.Mui-disabled': {
      color: `${COLORS.SILVER} !important`,
      '& svg': {
        opacity: 0.3,
      },
    },
  },
});

const SliderButton = ({
  className,
  onClick,
  next,
  disabled,
}) => {
  const classes = useStyles();
  const isDisabled =
    (className && className.includes('slick-disabled')) ||
    disabled;
  const icon = next ? (
    <ArrowRightIcon />
  ) : (
    <ArrowLefttIcon />
  );

  return (
    <Button
      onClick={onClick}
      className={classes.root}
      disabled={isDisabled}
    >
      {icon}
    </Button>
  );
};

SliderButton.propTypes = {
  className: string,
  onClick: func,
  next: bool,
  disabled: bool,
};

SliderButton.defaultProps = {
  className: '',
  next: false,
  disabled: false,
};

export default SliderButton;
